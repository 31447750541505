const endsWithSpace = (text: string) => {
  return text.endsWith(" ") || text.endsWith("&nbsp;");
};

const saveText = (text: string) => {
  localStorage.setItem("savedText", text);
};

export const clearText = () => {
  localStorage.setItem("savedText", "");
};

function setCursorToEnd() {
  const el = document.getElementById("editor");
  if (el) {
    const range = document.createRange();
    const sel = window.getSelection();
    range.selectNodeContents(el);
    range.collapse(false);
    if (sel) {
      sel.removeAllRanges();
      sel.addRange(range);
    }
    el.focus();
  }
}

function setCursorToStart() {
  const el = document.getElementById("editor");
  if (el) {
    el.innerHTML = "&nbsp;";
    const range = document.createRange();
    const sel = window.getSelection();
    range.selectNodeContents(el);
    range.collapse(true);
    if (sel) {
      sel.removeAllRanges();
      sel.addRange(range);
    }
    el.focus();
  }
}

export const editorUtils = {
  endsWithSpace,
  saveText,
  clearText,
  setCursorToEnd,
  setCursorToStart,
};
