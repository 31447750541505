import React, { useEffect, useState } from "react";
import { Paper, IconButton } from "@mui/material";
import "./Editor.css";
import sanitizeHtml from "sanitize-html";
import ContentEditable from "./wrapped-content-editable";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "./axios";
import {
  AutoAwesome,
  AutoMode,
  Translate,
  StarBorder,
  ContentCopy,
  Clear,
} from "@mui/icons-material";
import { editorUtils } from "./editorUtils";

type Completion = {
  completion: string;
};

type Improvement = {
  improvement: string;
};

type Translation = {
  translation: string;
};

export default function Editor() {
  const [text, setText] = useState("");
  const [completion, setCompletion] = useState("");
  const [improvement, setImprovement] = useState("");
  const [translation, setTranslation] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const savedText = localStorage.getItem("savedText");
    if (savedText) {
      setText(savedText);
    }
    editorUtils.setCursorToStart();
  }, []);

  async function getComplition() {
    setLoading(true);
    if (!editorUtils.endsWithSpace(text)) {
      setText(text.concat(" "));
    }
    const completeData = JSON.stringify({ text: text });
    try {
      const response = await axios.post<Completion>("/complete", completeData);
      setCompletion(response.data.completion);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    editorUtils.setCursorToEnd();
  }

  async function getImprovement() {
    setLoading(true);
    if (!editorUtils.endsWithSpace(text)) {
      setText(text.concat(" "));
    }
    const improvementData = JSON.stringify({ text: text });
    try {
      const response = await axios.post<Improvement>(
        "/improve",
        improvementData
      );
      setImprovement(response.data.improvement);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    editorUtils.setCursorToEnd();
  }

  async function getTranslation() {
    setLoading(true);
    if (!editorUtils.endsWithSpace(text)) {
      setText(text.concat(" "));
    }
    const translationData = JSON.stringify({ text: text });
    try {
      const response = await axios.post<Translation>(
        "/translate",
        translationData
      );
      setTranslation(response.data.translation);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    editorUtils.setCursorToEnd();
  }

  const onKeyDown = (e: { key: string; preventDefault: () => void }) => {
    if (e.key === "Tab" && (completion || improvement || translation)) {
      e.preventDefault();
      if (completion) {
        const combinedText = text.concat(completion);
        setText(combinedText);
        setCompletion("");
      } else if (improvement) {
        setText(improvement);
        setImprovement("");
      } else if (translation) {
        setText(translation);
        setTranslation("");
      }
      editorUtils.setCursorToEnd();
    }
  };

  const onContentChange = React.useCallback(
    (event: { currentTarget: { innerHTML: string } }) => {
      const sanitizeConf = {
        allowedTags: ["b", "i", "a", "p"],
        allowedAttributes: { a: ["href"] },
      };
      setText(
        sanitizeHtml(sanitizeHtml(event.currentTarget.innerHTML, sanitizeConf))
      );
    },
    []
  );

  return (
    <>
      <Paper
        sx={{
          p: 1,
          display: "flex",
          flexDirection: "column",
          msFlexDirection: "column",
          backgroundColor: "#FEF9EE",
          minHeight: "200px",
          marginTop: "20px",
          boxShadow: "none",
          border: "1px solid lightGrey",
        }}
      >
        <div className="TextsContainer">
          <div>
            <ContentEditable
              onChange={onContentChange}
              onBlur={onContentChange}
              html={text}
              className="Input"
              autoFocus
              inputMode="text"
              tabIndex={0}
              onInput={undefined}
              onKeyPress={onKeyDown}
              onKeyDown={onKeyDown}
              id="editor"
              placeholder="Type here..."
            />
            <span id="completion" className="Completion">
              {completion}
              {improvement}
              {translation}
            </span>
          </div>
          <IconButton
            size="small"
            sx={{
              alignSelf: "flex-end",
              height: "fit-content",
              marginRight: 1,
            }}
            disabled={!text ? true : false}
            onClick={() => {
              setText("");
              editorUtils.clearText();
              setCompletion("");
              setImprovement("");
              setTranslation("");
            }}
          >
            <Clear />
          </IconButton>
        </div>
        <div className="InnerButtons">
          <IconButton
            size="small"
            sx={{ p: 1 }}
            onClick={() => localStorage.setItem("savedText", text)}
            disabled={!text ? true : false}
          >
            <StarBorder />
          </IconButton>
          <CopyToClipboard text={text}>
            <IconButton
              size="small"
              sx={{ p: 1 }}
              disabled={!text ? true : false}
            >
              <ContentCopy />
            </IconButton>
          </CopyToClipboard>
        </div>
      </Paper>
      <div className="Buttons">
        <IconButton
          sx={{ m: 1 }}
          onClick={getTranslation}
          disabled={!text ? true : false}
        >
          <Translate />
        </IconButton>
        <IconButton
          sx={{ m: 1 }}
          onClick={getImprovement}
          disabled={!text ? true : false}
        >
          <AutoAwesome />
        </IconButton>
        <IconButton
          sx={{ m: 1 }}
          onClick={getComplition}
          disabled={!text ? true : false}
        >
          <AutoMode />
        </IconButton>
      </div>
    </>
  );
}
