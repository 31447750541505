import axios from "axios";

const instance = axios.create({
  baseURL: "https://fluently-be.herokuapp.com/text",
});

instance.defaults.headers.post["Content-Type"] = "application/json";
instance.defaults.headers.post["Accept"] = "application/json";
instance.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

export default instance;
